/* eslint-disable react/prop-types */
import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import SingleProjectsPage from '../components/ProjectsDetailPage';
import SEO from '../components/seo';

const ProjectPage = ({ data, pageContext }) => {
  const { metadata, localizations } = data.strapiProjects;
  const global = data.strapiGlobal;
  return (
    <>
      <SEO seo={metadata} global={global} />
      <Layout global={global} pageContext={{ ...pageContext, localizations }}>
        <SingleProjectsPage
          data={data.strapiProjects}
          pageContext={{ ...pageContext, localizations }}
        />
      </Layout>
    </>
  );
};

export default ProjectPage;

export const query = graphql`
  fragment GlobalData on StrapiGlobal {
    navbar {
      image {
        url
      }
      mainLinks {
        id
        text
        url
      }
    }
    metadata {
      metaDescription
      id
      metaTitle
    }
    locale
    localizations {
      id
      locale
    }
    footer {
      aboutTitle
      companyName
      contact {
        address
        email
        id
        tel
      }
      contactTitle
      copyright {
        id
        title
      }
      id
      serviceTitle
      services {
        id
        slug
        title
      }
      about {
        id
        slug
        title
      }
    }
    locale
  }
  query projectsPageQuery($id: String!, $locale: String!) {
    strapiGlobal(locale: { eq: $locale }) {
      ...GlobalData
    }
    strapiProjects(id: { eq: $id }) {
      image {
        url
      }
      images {
        url
      }
      slug
      title
      location
      locale
      projectDescription
      projectContent
      client
      clientName
      locationName
      year
      yearNumber
      otherProjectsTitle
      goToButton
      category
    }
  }
`;
