/* eslint-disable no-console */
/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { Fade } from 'react-reveal';
import { Carousel } from '@trendyol-js/react-carousel';
import { SlClose } from 'react-icons/sl';
import ReactMarkdown from 'react-markdown';

import CarouselRightArrow from '../Arrows/carouselArrowRight';
import CarouselLeftArrow from '../Arrows/carouselArrowLeft';
import PrevSlickSliderArrow from '../Arrows/prevSlickSliderArrow';
import NextSlickSliderArrow from '../Arrows/nextSlickSliderArrow';
import ModalLeftArrow from '../Arrows/modalLeftArrow';
import ModalRightArrow from '../Arrows/modalRightArrow';
import CustomLink from '../CustomLink';
import { localizePath } from '../../utils/localize';
import useProjects from '../../queries/projectsQueries';
import './project.scss';

const SingleProjectsPage = (props) => {
  const [isMobile, setIsMobile] = useState(true);
  const { pageContext } = props;
  const projectsData = useProjects();
  const langProjectsData = projectsData.filter(
    (item) => item.locale === props.data.locale,
  );

  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };
  const inputString = props.pageContext.slug;
  const wordsArray = inputString.split('-');
  const firstWord = wordsArray[0];

  const filteredProjects = langProjectsData.filter((item) =>
    item.category.includes(firstWord),
  );

  useEffect(() => {
    if (window.innerWidth >= 768) setIsMobile(false);
  }, []);
  const settings = {
    dots: false,
    arrows: true,
    fade: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <PrevSlickSliderArrow />,
    nextArrow: <NextSlickSliderArrow />,
  };
  const settingsModal = {
    dots: false,
    arrows: true,
    fade: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <ModalLeftArrow />,
    nextArrow: <ModalRightArrow />,
  };

  return (
    <div className="main_wrapper">
      <Fade>
        <div
          onClick={closeModal}
          className={`modal_overlay ${isModalOpen ? 'open' : ''}`}
        />
      </Fade>
      {/* mobile */}
      <div
        className="project_hero_mobile"
        style={{ backgroundImage: `(${props.data?.image?.url})` }}
      >
        <div className="project_title_container">
          <p className="project_title_mobile">{props.data.title}</p>
          <p className="project_location">{props.data.location}</p>
        </div>
      </div>
      {/* desktop */}
      <div className="project_hero_desktop">
        <img className="image_desktop" src={props.data?.image?.url} alt="" />
        <div className="title_container_desktop">
          <p className="project_title_desktop">{props.data.title}</p>
          <p className="location_desktop">{props.data.location}</p>
        </div>
      </div>
      <div className="project_content_wrapper">
        <div className={`images_modal_wrapper ${isModalOpen ? 'open' : ''}`}>
          <SlClose
            type="button"
            onClick={closeModal}
            aria-label="Close Modal"
            className="close_btn_icon"
          />
          <Slider
            style={{
              backdropFilter: 'blur(8px)',
              backgroundColor: 'rgba(255, 255, 255, 0.5)',
              borderRadius: '40px',
            }}
            className="modal_image_carousel_container"
            {...settingsModal}
          >
            {props.data.images.map((item) => (
              <img
                key={item.url}
                className="modal_carousel_image"
                src={item.url}
                alt={`Project ${item.id + 1}`}
              />
            ))}
          </Slider>
        </div>
        <div className="project_left_container">
          <h3 className="project_description">
            {props.data.projectDescription}
          </h3>
          <ReactMarkdown className="project_content">
            {props.data.projectContent}
          </ReactMarkdown>
          <div className="project_info_wrapper">
            <p className="project_info_client">
              {`${props.data.client}:`}
              <span className="project_info_content">
                {props.data.clientName}
              </span>
            </p>
            <p className="project_info_location">
              {`${props.data.location}:`}
              <span className="project_info_content">
                {props.data.locationName}
              </span>
            </p>
            <p className="project_info_year">
              {`${props.data.year}:`}
              <span className="project_info_content">
                {props.data.yearNumber}
              </span>
            </p>
          </div>
        </div>
        <div className="project_right_container">
          <Slider className="project_image_slider_container" {...settings}>
            {props.data.images.map((item) => (
              <button
                className="image_btn"
                type="button"
                tabIndex="0"
                onClick={openModal}
              >
                <img
                  key={item.id}
                  className="project_images"
                  src={item.url}
                  alt={`Project ${item.id + 1}`}
                />
              </button>
            ))}
          </Slider>
        </div>
      </div>
      <div className="other_projects_container">
        <h3 className="other_projects_title">
          {props.data.otherProjectsTitle}
        </h3>
        <Carousel
          show={!isMobile ? 3 : 1}
          slide={1}
          responsive
          swiping
          leftArrow={<CarouselLeftArrow />}
          rightArrow={<CarouselRightArrow />}
          className="project_card_container"
        >
          {filteredProjects.map((item) => (
            <div key={item.slug}>
              <div
                className="container"
                key={item.slug}
                style={{ backgroundImage: `url(${item.image?.url})` }}
              >
                <div className="image-overlay">
                  <div className="middle">
                    <p className="text text_1">{item.title}</p>
                    <p className="text text_2">{item.location}</p>
                  </div>
                </div>
                <CustomLink
                  link={{
                    url: `${localizePath({
                      ...pageContext,
                      isPreview: false,
                      slug:
                        props.pageContext.locale === 'bs'
                          ? `${item.slug}`.slice(0)
                          : item.slug,
                    })}`,
                  }}
                  to={`/${item.slug}`}
                >
                  <button type="button" className="go_to_projects">
                    {props.data.goToButton}
                  </button>
                </CustomLink>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
};
SingleProjectsPage.propTypes = {
  data: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    year: PropTypes.string.isRequired,
    goToButton: PropTypes.string.isRequired,
    goToProject: PropTypes.string.isRequired,
    yearNumber: PropTypes.string.isRequired,
    otherProjectsTitle: PropTypes.string.isRequired,
    locationName: PropTypes.string.isRequired,
    clientName: PropTypes.string.isRequired,
    client: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    projectContent: PropTypes.string.isRequired,
    projectDescription: PropTypes.string.isRequired,
    image: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }).isRequired,
    images: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    defaultLocale: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    localizedPaths: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string,
        href: PropTypes.string,
      }),
    ).isRequired,
  }).isRequired,
};

export default SingleProjectsPage;
